<template>
  <div class="plans py-2 ">
    <div class="row">
      <div class="col-12 text-center">
        <h5 class="font-weight-bold text-muted">Conoce nuestro plan para ti</h5>
      </div>
      <div class="col-12 text-center">
        <p class="lead">Escoja el que mejor se adapte a tus necesidades</p>
      </div>



       
            
          <Carousel
          v-bind:autoplay="true"
          v-bind:perPageCustom="[
            [0, 1],
            [720, 1],
            [1920, 1],
          ]"
          v-bind:scrollPerPage="true"
        >
          <Slide
            class=""
            v-for="plan in plans"
            v-bind:key="plan.id"
          >
           
            <div class="container-fluid mt-1 mb-5 table-responsive">
                <table class="table table-bordered table-striped">
                    <tbody>
                        <tr>
                            <th scope="column">
                            <span class="font-weight-light" style="font-size: 20px">Descripción de Cobertura</span> 
                            </th> 
                            <td>
                                <div class=" text-center"> 
                                <b>Monto Asegurado</b>
                                </div> 

                            </td>
                            <td>
                            <div class=" text-center"> 
                                <b>Monto Asegurado</b>
                                </div> 
                            </td>
                            <td>
                              <div class=" text-center"> 
                                <b>Monto Asegurado</b>
                                </div> 
                            </td>
                        </tr>
                        
                        <tr  v-for="(feature, idx) in plan.features"
                                v-bind:key="idx">
                          <th scope="row"> 
                                <span class="d-block">{{feature.description}}</span> 
                            </th> 
                            <td>
                                <div class="text-center check" >  <i v-if="getPriceCost(feature, 0) == true" class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i> <span v-else> {{getPriceCost(feature, 0)}} </span> </div>
                            </td>
                            <td>
                                <div class="text-center check"> <i v-if="getPriceCost(feature, 1) == true" class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i>  <span v-else> {{getPriceCost(feature, 1)}} </span>  </div>
                            </td>
                            <td>
                                <div class="text-center check"><i v-if="getPriceCost(feature, 2) == true" class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i>  <span v-else> {{getPriceCost(feature, 2)}} </span></div>
                            </td>
                        </tr>
                        <tr v-if="isZoneCastratophic">
                          <td></td>
                          
                          <td>
                            <div class="text-center check">
                            <i class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i>
                            </div>
                          </td>
                          <td>
                            <div class="text-center check">
                            <i class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i>
                            </div>
                          </td>
                          <td>
                            <div class="text-center check">
                            <i class="uil uil-check-circle align-middle" v-b-tooltip.hover.top title="Amparada"></i>
                            </div>
                          </td>
                      
                        
                        </tr>
                        <tr>
                          <td></td>
                         
                          <td class="text-center">
                            Seleccionar
                            <button class="btn btn-primary btn-block normal-button mt-2 btn-sm">  {{costsZone1 || '-'}}  </button>
                          </td>
                          <td class="text-center">
                            Seleccionar
                            <button class="btn btn-primary btn-block normal-button mt-2 btn-sm">  {{costsZone2 || '-'}}  </button>
                          </td>
                          <td class="text-center">
                            Seleccionar
                            <button class="btn btn-primary btn-block normal-button mt-2 btn-sm">  {{costsZone3 || '-'}} </button>
                          </td>
                        </tr>

                        

                
                    </tbody>
                </table>
            </div>
                  
           
          </Slide>
        </Carousel>
       
  

        

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import { firestore, } from "@/services/firebase.service";
import { zoneCosts } from "@/services/costco.service";
export default {
  
  data(){
    return{
      // plans: []
      costs: [0,0,0],
      costsZone1: 0, 
      costsZone2: 0,
      costsZone3: 0,
      isZoneCastratophic: false
    }
  },
  props: [
    'zipCode',
  ],

  created(){
    this.getZoneCost()
  },

  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters({
      plans: "plans/ordered",
    }),
   
  },
  methods: {

     formatterAmount(value){

      return `$ ${new Intl.NumberFormat("es-CO", { currency: "COP" }).format(value)}`
      // return `${value}%`;
    },

    async getZoneCost(){
      try {
        const data = await zoneCosts(22020)
        this.costs = data.costs
        // this.costsZone1 = data.costs[0]
        // this.costsZone2 = data.costs[1]
        // this.costsZone3 = data.costs[2]
        this.isZoneCastratophic = data.isZoneCastratophic
        //console.log(data);
      } catch (error) {
        console.log(error);
      }
  
    },

    async getPlans(){
      try {
         const snap = await  firestore.collection("plans").get()
         
         snap.forEach(doc => {
            this.plans.push({ id: doc.id, ...doc.data()})
         });

      } catch (a) {
        
      }
    },

    getPriceCost(feature, idx){
      if(feature.costs){
        return this.formatterAmount(feature.costs[idx])
      }

      if(feature.values){
        return feature.values[idx]
      }

      return  '-'
    },

    printFilter() {
      console.log(this.filter);
    },

    getBillingFrequencyAbbr(frequency) {
      switch (frequency) {
        case "daily":
          return "day";
        case "weekly":
          return "week";
        case "monthly":
          return "month";
        case "yearly":
          return "year";
      }
    },
  },
};
</script>

<style lang="scss">
.VueCarousel-wrapper {
  padding-bottom: 1rem;
}


.check i {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    // background-color: #b9f6ca82;
    line-height: 38px;
    font-size: 27px;
    color: #2674b4;
    font-weight: 300
}
</style>